import service from '@/utils/request';

// @Tags PublicDNSConf
// @Summary Create a new public dns conf
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PubdnsConfRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsconf [post]

export var createPubDnsConf = function createPubDnsConf(data) {
  return service({
    url: "/pubdnsconf",
    method: 'post',
    data: data
  });
};

// @Tags PublicDNSConf
// @Summary List all pubdns confs
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param name query string false "fuzzy query by name of conf"
// @Param page query int false "page number"
// @Param pageSize query int false "page size"
// @Success 200 {object} response.Response{data=response.ResponseResult{zone=[]response.ZoneResponse,total=int,owner=string}} "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsconf [get]

export var getPubdnsConfList = function getPubdnsConfList(params) {
  return service({
    url: "/pubdnsconf",
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags PublicDNSConf
// @Summary Delete a public dns conf by authcode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsconf/:authcode [delete]

export var deletePubDnsConf = function deletePubDnsConf(params) {
  return service({
    url: "/pubdnsconf/" + params.authCode,
    method: 'delete',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags PublicDNSConf
// @Summary View a publicdns conf details
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param page query int false "page number"
// @Param pageSize query int false "page size"
// @Success 200 {object} response.Response{data=response.ResponseResult{zone=response.ZoneResponse,domain=response.DomainResponse,total=int,owner=string}} "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsconf/:authcode [get]

export var getOnePubdnsConf = function getOnePubdnsConf(params) {
  return service({
    url: "/pubdnsconf/" + params.authCode,
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags PublicDNSConf
// @Summary update bindip in public dns conf by authcode
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PubdnsConfRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsconf/:authcode [put]

export var updatePubDnsConf = function updatePubDnsConf(data) {
  return service({
    url: "/pubdnsconf/" + data.authCode,
    method: 'put',
    data: data
  });
};

// @Tags Tools
// @Summary get request ip
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body null true
// @Success 200 {string} string "{"success":true,"data":{},"msg":"Action successful"}"
// @Router /tools/getrequetip [get]

export var getrequetip = function getrequetip(params) {
  return service({
    url: "/tools/getrequetip",
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};